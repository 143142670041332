import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
//import IconButton from '@mui/material/IconButton';
//import MenuIcon from '@mui/icons-material/Menu';
//import { Link } from 'react-router-dom';

export default function Header() {
  return (
    <AppBar position="static">
      <Toolbar>
        {/*Inside the IconButton, we 
					can render various icons*/}
        {/* The Typography component applies 
					default font weights and sizes */}

        <Typography
          fontFamily={'Brush Script MT'}
          fontSize={40}
          variant="h6"
          component="div"
          sx={{ flexGrow: 1 }}
          align="left"
        >
          Vad skall vi se?
        </Typography>
        <Typography
          fontFamily={'Brush Script MT'}
          fontSize={24}
          variant="h6"
          component="div"
          sx={{ flexGrow: 1 }}
          align="right"
        >
          din guide i mediedjungeln
        </Typography>
      </Toolbar>
    </AppBar>
  );
}
