import * as React from 'react';
import { View, Image, StyleSheet, TouchableOpacity } from 'react-native';
import Typography from '@mui/material/Typography';
import Db from './db.json';

const styles = StyleSheet.create({
  container: {
    paddingTop: 25,
    flexDirection: 'row',
  },
  stretch: {
    width: 100,
    height: 36,
    resizeMode: 'center',
    marginEnd: 10,
  },
});

export default function Selection(props: any) {
  const handlePress = () => {
    props.onDataChange(Db[0]);
  };

  return (
    <View style={styles.container}>
      <Typography
        fontFamily={'Brush Script MT'}
        fontSize={24}
        variant="h6"
        component="div"
        sx={{ flexGrow: 1 }}
        align="left"
      >
        Vilka tjänster?
      </Typography>
      <TouchableOpacity onPress={handlePress}>
        <Image
          style={styles.stretch}
          source={require('./images/services/Svt.png')}
        />
      </TouchableOpacity>
      <TouchableOpacity onPress={handlePress}>
        <Image
          style={styles.stretch}
          source={require('./images/services/tv4play.png')}
        />
      </TouchableOpacity>
      <TouchableOpacity onPress={handlePress}>
        <Image
          style={styles.stretch}
          source={require('./images/services/DiscoveryPlus.png')}
        />
      </TouchableOpacity>
    </View>
  );
}
