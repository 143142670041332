/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import './App.css';
import * as React from 'react';
import Header from './Header';
//import LoginCreate from './LoginCreate';
//import Login from './Login';
import Selection from './Selection';
//import { Routes, Route } from 'react-router-dom';
import Content from './Content';
import Action from './Action';

class App extends React.Component {
  state = {
    data: {},
  };

  handleDataChange = (data: any) => {
    this.setState({ data });
  };

  render() {
    return (
      <div
        css={css`
          text-align: center;
          max-width: 800px;
          margin: auto;
          font-family: 'Segoe UI', 'Helvetica Neue', sans-serif;
          font-size: 16px;
          color: #5c5a5a;
        `}
      >
        <Header />
        <img src="/images/banner.jpg" alt="banner" />
        <Selection
          data={this.state.data}
          onDataChange={this.handleDataChange}
        />
        <hr
          style={{
            color: '#2222d1',
            backgroundColor: '#2222d1',
            height: 2,
            marginLeft: 80,
            marginRight: 80,
          }}
        />
        <Action />
        <Content
          data={this.state.data}
          onDataChange={this.handleDataChange}
        ></Content>
      </div>
    );
  }
}

export default App;
