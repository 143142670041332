import { Typography } from '@mui/material';
import { Image, View, StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    paddingTop: 25,
    flexDirection: 'column',
  },
  stretch: {
    height: 300,
    width: 800,
    resizeMode: 'center',
  },
});

export default function Content(props: any) {
  if (!props.data.image) props.data.image = 'NoraSand.jpg';

  return (
    <View style={styles.container}>
      <Typography
        fontFamily={'Georgia (serif)'}
        fontSize={30}
        fontWeight={'bold'}
        variant="h6"
        component="div"
        sx={{ flexGrow: 1 }}
        align="center"
        marginBlockStart="50"
      >
        {props.data.title ? props.data.title : ''}
      </Typography>
      <Image
        style={styles.stretch}
        source={require(`./images/media/${props.data.image}`)}
      />
      <Typography
        fontFamily={'Georgia (serif)'}
        fontSize={18}
        variant="h6"
        component="div"
        sx={{ flexGrow: 1 }}
        align="center"
        marginBlockStart="50"
      >
        {props.data.text ? props.data.text : ''}
      </Typography>
    </View>
  );
}
