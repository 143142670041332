import { Button } from '@mui/material';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  button: {
    backgroundColor: 'blue',
    color: 'white',
    borderRadius: 5,
  },
});

export default function Action() {
  return <Button style={styles.button}>Föreslå något</Button>;
}
